import React from "react"

import Blog from "../blog-config.json";
import Layout from "../components/layout"
import SEO from "../components/seo"
import OurCard from "../components/OurCard";
import BlogPostHeader from "../components/BlogPostHeader";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <BlogPostHeader title={"Welcome,"} subTitle={"The moment is all we have."}/>
      <div style={{ marginBottom: `1.45rem` }}>
        {Object.entries(Blog.posts).map(([key, item]) => (item.visible &&
          <>
            <OurCard imgSrc={item.featuredImage} linkTo={item.slug} title={item.title} subTitle={item.subTitle}></OurCard>
            <br/>
          </>
        ))}
      </div>
    </Layout>
  )
}

export default IndexPage
