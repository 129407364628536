import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        maxWidth: '100%',
    },
    forwardLink: {
        cursor: 'pointer'
    }
});

export default function ImgMediaCard({linkTo, title, subTitle, imgSrc}) {
    const classes = useStyles();

    const forwardToLink = (link) => {
        navigate(link)
    }

    return (
        <Card className={classes.root}>
            <div className={"cursor-pointer"} onClick={() => forwardToLink(linkTo)}>
                <CardMedia
                component="img"
                alt={`Blog Post Featured Image for ${title}`}
                height="250"
                image={imgSrc}
                title={title}
                style={{pointerEvents: 'none'}}
                />
                <CardContent>
                <Typography className={"white-monospace"} gutterBottom variant="h4" component="h2">
                    {title}
                </Typography>
                <Typography className={"white-monospace"} variant="h6" component="p">
                    {subTitle}
                </Typography>
                </CardContent>
            </div>
            {/* <CardActions>
                <Button size="small" color="primary">
                Share
                </Button>
                <Button size="small" color="primary">
                Learn More
                </Button>
            </CardActions> */}
        </Card>
    );
}